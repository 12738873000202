@import "/styles/variables.scss";

.container {
  position: fixed;
  z-index: $z-toast;
  left: 0;
  right: 0;
  height: 0;

  .inner {
    background-color: $dark-blue;
    transition: all 0.15s ease-out;
    transform-origin: top;
    color: $white;
    padding: 0 24px;
    text-align: center;

    &.visible {
      transform: scaleY(1);
      padding: 16px 24px;
    }

    &.hidden {
      transform: scaleY(0);
    }

    > a {
      color: $white;
      text-align: center;
    }

    > div {
      display: flex;
      align-items: center;

      p:first-child {
        flex: 1;
      }
    }
  }

  .closeButton {
    color: $white;
    float: right;
    padding: 4px;

    &:hover {
      cursor: pointer;
      color: $red;
    }
  }
}
