@import "_reset.scss";
@import "variables.scss";
@import "_fonts.scss";
@import "_typography.scss";
@import "_forms.scss";
@import "_buttons.scss";
@import "icomoon.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  background-color: rgba($light-blue, 0.75);
}

::input-placeholder {
  color: $secondary-mgray;
}

::-moz-input-placeholder {
  color: $secondary-mgray;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
}

a {
  color: $red;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.button {
  text-decoration: none;
  color: $white;
  text-align: center;
  text-transform: uppercase;
  padding: 18px 30px;
  background-color: $red;
  border-radius: 3px;
  appearance: none;
  font-size: 14px;
  height: 50px;
}

a.disabled {
  color: $white;
  text-align: center;
  background-color: $red;
  border-radius: 3px;
  padding: 18px 30px;
  font-size: 14px;
  text-transform: uppercase;
  appearance: none;
  // adisabled
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  height: 50px;
}

p,
footer {
  line-height: 1.7;
}

address {
  font-style: inherit;
  line-height: 1.9;
}

small,
abbr {
  font-size: 12px;
  color: $text-dgray;
  vertical-align: middle;
}

button {
  border: none;
  line-height: 1;
}

[class^="icon-"] {
  font-size: 125%;
  vertical-align: sub;
}

.inset,
.inset-layout {
  margin: 0 auto;
  max-width: $layout-max-width;
  width: 100%;

  // target p tags where the previous sibling is a h1 and they're the second element in their parent */
  // > h1 + p:nth-child(2) {
  //   text-align: center;
  // }
}

.inset {
  padding: $body-padding-mobile;

  @media screen and (min-width: $desktop-min-width) {
    padding: $body-padding;
  }
}

.mobile-only {
  @media screen and (min-width: $desktop-min-width) {
    display: none;
  }
}

.tablet-only {
  @media screen and (max-width: $bp-tablet) {
    display: none;
  }
}

.desktop-only {
  @media screen and (max-width: $mobile-max-width) {
    display: none;
  }
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  text-shadow: none;
}

// .left {
//   float: left;
// }

// .right {
//   float: right;
// }
