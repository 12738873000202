/* Ideally this should be in the project, not just hardcoded. But due to miyagi-legacy/miyagi-next splits and the ALB, it makes sense to hard code this for a while since it changes so infrequently. The main downside is that local dev needs to fetch from the web for fonts to look right */

$font-path: "https://miyagi-next-assets.s3.us-east-005.backblazeb2.com/next-assets/";

@font-face {
  font-display: swap;
  font-family: "Maison Neue";
  src:
    url("#{$font-path}MaisonNeueWEB-Medium.woff2") format("woff2"),
    url("#{$font-path}MaisonNeueWEB-Medium.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Maison Neue";
  font-weight: bold;
  src:
    url("#{$font-path}MaisonNeueWEB-Bold.woff2") format("woff2"),
    url("#{$font-path}MaisonNeueWEB-Bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Maison Neue";
  font-weight: 300;
  src:
    url("#{$font-path}MaisonNeueWEB-Light.woff2") format("woff2"),
    url("#{$font-path}MaisonNeueWEB-Light.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Circular Std";
  src: url("#{$font-path}lineto-circular-bold.eot");
  src:
    url("#{$font-path}lineto-circular-bold.eot?#iefix")
    format("embedded-opentype"),
    url("#{$font-path}lineto-circular-bold.woff2") format("woff2"),
    url("#{$font-path}lineto-circular-bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-display: swap;
  font-family: "Circular Std";
  src: url("#{$font-path}lineto-circular-book.eot");
  src:
    url("#{$font-path}lineto-circular-book.eot?#iefix")
    format("embedded-opentype"),
    url("#{$font-path}lineto-circular-book.woff2") format("woff2"),
    url("#{$font-path}lineto-circular-book.woff") format("woff");
  font-weight: normal;
}

html,
body,
input,
textarea,
select,
button {
  font-family: $heading-font;
  font-weight: normal;
}

strong,
b {
  font-weight: bold;
}

caption,
small,
abbr {
  font-family: $body-font;
}

h2,
h3,
h4,
h5,
h6,
th {
  font-family: $heading-font;
  font-weight: bold;

  a {
    color: inherit;
  }
}
