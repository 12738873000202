@font-face {
  font-family: 'icomoon';
  src:  url('https://d1azc1qln24ryf.cloudfront.net/97723/miyagi/icomoon.eot?aphn56');
  src:  url('https://d1azc1qln24ryf.cloudfront.net/97723/miyagi/icomoon.eot?aphn56#iefix') format('embedded-opentype'),
    url('https://d1azc1qln24ryf.cloudfront.net/97723/miyagi/icomoon.woff2?aphn56') format('woff2'),
    url('https://d1azc1qln24ryf.cloudfront.net/97723/miyagi/icomoon.ttf?aphn56') format('truetype'),
    url('https://d1azc1qln24ryf.cloudfront.net/97723/miyagi/icomoon.woff?aphn56') format('woff'),
    url('https://d1azc1qln24ryf.cloudfront.net/97723/miyagi/icomoon.svg?aphn56#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-move:before {
  content: "\e962";
}
.icon-drag:before {
  content: "\e962";
}
.icon-check-alt:before {
  content: "\e951";
}
.icon-file-symlink-file:before {
  content: "\f0b0";
}
.icon-file-text:before {
  content: "\f011";
}
.icon-organization:before {
  content: "\f037";
}
.icon-person:before {
  content: "\f018";
}
.icon-question:before {
  content: "\f02c";
}
.icon-pencil:before {
  content: "\e913";
}
.icon-equalizer:before {
  content: "\e960";
}
.icon-sliders:before {
  content: "\e960";
}
.icon-settings:before {
  content: "\e960";
}
.icon-preferences:before {
  content: "\e960";
}
.icon-dashboard:before {
  content: "\e960";
}
.icon-control:before {
  content: "\e960";
}
.icon-copy:before {
  content: "\e95d";
}
.icon-spinner:before {
  content: "\e957";
}
.icon-floppy-disk:before {
  content: "\e955";
}
.icon-cell-phone:before {
  content: "\e914";
}
.icon-layout:before {
  content: "\e95e";
  color: #aaa;
}
.icon-user:before {
  content: "\e95f";
  color: #aaa;
}
.icon-calendar-2:before {
  content: "\e949";
  color: #aaa;
}
.icon-pivotdesk-logo:before {
  content: "\e953";
  color: #aaa;
}
.icon-office-desk:before {
  content: "\e94c";
}
.icon-parking:before {
  content: "\e952";
}
.icon-furniture:before {
  content: "\e908";
}
.icon-cubicle:before {
  content: "\e94d";
}
.icon-cubicles:before {
  content: "\e94d";
}
.icon-kitchen:before {
  content: "\e94e";
}
.icon-dog-friendly:before {
  content: "\e94f";
}
.icon-edit-pencil:before {
  content: "\e946";
}
.icon-bookmark-filled:before {
  content: "\e92c";
}
.icon-lighting-none:before {
  content: "\e931";
}
.icon-lighting-poor:before {
  content: "\e932";
}
.icon-lighting-good:before {
  content: "\e933";
}
.icon-lighting-very_good:before {
  content: "\e934";
}
.icon-lighting-excellent:before {
  content: "\e935";
}
.icon-bathroom-shared:before {
  content: "\e936";
}
.icon-bathroom-private:before {
  content: "\e937";
}
.icon-wet-pantry:before {
  content: "\e938";
}
.icon-conference-rooms:before {
  content: "\e939";
}
.icon-conference-room:before {
  content: "\e950";
}
.icon-exposed-ceiling:before {
  content: "\e93a";
}
.icon-floor-carpet:before {
  content: "\e93b";
}
.icon-floor-concrete:before {
  content: "\e93c";
}
.icon-floor-hardwood:before {
  content: "\e93d";
}
.icon-brand-mark:before {
  content: "\e954";
}
.icon-floppy-o:before {
  content: "\e956";
}
.icon-alert-circle:before {
  content: "\e940";
}
.icon-arrow-down:before {
  content: "\e95a";
}
.icon-arrow-up:before {
  content: "\e959";
}
.icon-award:before {
  content: "\e922";
}
.icon-bookmark:before {
  content: "\e92b";
}
.icon-box:before {
  content: "\e92d";
}
.icon-briefcase:before {
  content: "\e92e";
}
.icon-camera-off:before {
  content: "\e92f";
}
.icon-check-circle:before {
  content: "\e928";
}
.icon-check-square:before {
  content: "\e925";
}
.icon-check:before {
  content: "\e901";
}
.icon-chevron-down:before {
  content: "\e902";
}
.icon-chevron-left:before {
  content: "\e903";
}
.icon-chevron-right:before {
  content: "\e907";
}
.icon-chevron-up:before {
  content: "\e90a";
}
.icon-clipboard:before {
  content: "\e90b";
}
.icon-clock:before {
  content: "\e95c";
}
.icon-cog:before {
  content: "\e90c";
}
.icon-comment-square:before {
  content: "\e961";
}
.icon-edit:before {
  content: "\e90d";
}
.icon-external-link:before {
  content: "\e90e";
}
.icon-eye-off:before {
  content: "\e942";
}
.icon-eye:before {
  content: "\e943";
}
.icon-flag:before {
  content: "\e926";
}
.icon-grid:before {
  content: "\e909";
}
.icon-heart:before {
  content: "\e919";
}
.icon-image:before {
  content: "\e911";
}
.icon-info:before {
  content: "\e94b";
}
.icon-location:before {
  content: "\e947";
}
.icon-mail:before {
  content: "\e912";
}
.icon-map-pin:before {
  content: "\e91b";
}
.icon-map:before {
  content: "\e91c";
}
.icon-minus:before {
  content: "\e91d";
}
.icon-more-vertical:before {
  content: "\e964";
}
.icon-phone2:before {
  content: "\e923";
}
.icon-plus-circle:before {
  content: "\e945";
}
.icon-plus:before {
  content: "\e91e";
}
.icon-refresh:before {
  content: "\e944";
}
.icon-search:before {
  content: "\e918";
}
.icon-star:before {
  content: "\e91a";
}
.icon-sun:before {
  content: "\e924";
}
.icon-thumbs-down:before {
  content: "\e929";
}
.icon-thumbs-up:before {
  content: "\e92a";
}
.icon-trashcan:before {
  content: "\e91f";
}
.icon-trashcan2:before {
  content: "\e948";
}
.icon-upload:before {
  content: "\e95b";
}
.icon-video:before {
  content: "\e906";
}
.icon-wifi:before {
  content: "\e941";
}
.icon-wi-fi:before {
  content: "\e941";
}
.icon-cross:before {
  content: "\e920";
}
.icon-zoom-in:before {
  content: "\e921";
}
.icon-drag_indicator:before {
  content: "\e963";
}
.icon-chat-bubble:before {
  content: "\e910";
}
.icon-warning:before {
  content: "\e93f";
}
.icon-chevron-thin-left:before {
  content: "\e94a";
}
.icon-save:before {
  content: "\e958";
}
.icon-compass:before {
  content: "\e93e";
}
.icon-forward:before {
  content: "\e915";
}
.icon-help:before {
  content: "\e900";
}
.icon-link:before {
  content: "\e90f";
}
.icon-new-message:before {
  content: "\e916";
}
.icon-paper-plane:before {
  content: "\e917";
}
.icon-phone:before {
  content: "\e904";
}
.icon-print:before {
  content: "\e905";
}
.icon-client-search-filter:before {
  content: "\e930";
}
.icon-star-filled:before {
  content: "\e927";
}