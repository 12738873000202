@use "styles/variables.scss";

@mixin base-button {
  background-color: $red;
  color: white;
  padding: 15px;
  border-radius: 3px;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  /* stylelint-disable plugin/no-low-performance-animation-properties */
  transition: box-shadow 0.25s ease-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    background-color: #d84545;
  }
}

.btn-primary {
  @include base-button();
}

.btn-outline {
  @include base-button();

  background-color: transparent;
  color: $red;
  border: 1px solid $red;

  &:focus {
    color: white;
    border: none;
    background-color: #d84545;
  }
}

.btn-small {
  @include base-button();

  padding: 7px 11px;
}

.btn-text {
  @include base-button();

  background-color: transparent;
  color: $red;
  padding: 16px;

  &:hover {
    box-shadow: none;
  }

  &:focus {
    background-color: unset;
  }
}

.btn-wide {
  @include base-button();

  width: 100%;
}
