@import "variables.scss";

@mixin headline {
  font-size: 36px;
  letter-spacing: -1.5px;
  font-weight: bold;

  @media (max-width: $mobile-max-width) {
    font-size: 26px;
  }
}

.bold {
  font-weight: bold !important;
}

.fake-link {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.bodyEmphasis {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.caption {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
}

.captionEmphasis {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
}

.display {
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -2px;

  @media (max-width: $mobile-max-width) {
    font-size: 36px;
  }
}

.headline {
  @include headline();
}

.title {
  font-size: 36px;
  letter-spacing: -1.5px;
  font-weight: normal;

  @media (max-width: $mobile-max-width) {
    font-size: 26px;
  }
}

.subheader {
  font-size: 20px;
  letter-spacing: -0.5px;
  font-weight: normal;
}

.subheaderEmphasis {
  font-size: 20px;
  letter-spacing: -0.5px;
  font-weight: bold;
}

.micro {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
}

.heading1 {
  font-family: $heading-font;
  font-weight: bold;

  a {
    color: inherit;
  }
}

.heading2 {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 16px;
  color: $dark-blue;
  letter-spacing: -1.3px;
}
