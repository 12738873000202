$spacing-extra-small: 6px;
$spacing-small: 12px;
$spacing-medium: 24px;
$spacing-large: 48px;
$spacing-extra-large: 96px;

$light-blue: #bef6ff;
$dark-blue: #004f8a;
$red: #ff5050;
$dark-red: #cc4040;
$translucent-red: rgba($red, 0.1);
$off-white: #fafafa;
$white: white;
$black: black;
$gray: #aaa;
$light-gray: #ddd;
$orange: #ffb534;

$primary-black: #3a3e41;

$secondary-black: #212121;
$secondary-dgray: #6b7175;
$secondary-mgray: #aaadae;
$secondary-lgray: #f5f5f8;

$green: #22d19b;
$dgreen: #00ad7e;

$text-black: #3a3e41;
$text-dgray: #888;
$text-lgray: #e5e5e5;
$text-green: #008f68;

$active-dgreen: #16ac7f;

$info: #fff7eb;
$info-border: #fecf7f;

$border-mgray: #ddd;
$border-lgray: #e5e5e5;

$shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
$inset-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);

$border-radius: 2px;
$body-padding: 48px;
$body-padding-mobile: 10px;

$bp-mobile: 960px;
$bp-mobile-landscape: 480px;
$bp-tablet: 1080px;

$xs-max-width: 321px;
$mobile-max-width: 959px;
$desktop-min-width: 960px;
$layout-max-width: 1440px;

$body-font: "Maison Neue", "Karla", sans-serif;
$heading-font: "Circular Std", helvetica, arial, sans-serif;

$z-hidden: -1;
$z-main: 0;
$z-search-map: 1;
$z-footer: 3;
$z-search-drawer: 4;
$z-search-results: 5;
$z-search-filters: 6;
$z-inner-navigation: 7;
$z-header: 8;
$z-floating: 9;
$z-modal: 12;
$z-toast: 13;

$z-marker-background: 5000;
$z-marker-middle: 6000;
$z-marker-foreground: 7000;
