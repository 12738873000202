input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="time"],
input[type="submit"],
textarea,
.select {
  display: inline-block;
  vertical-align: middle;
  padding: 14px 12px;
  border: none;
  font-size: 1em;
  line-height: 1;
  transition: all 0.1s ease-out;
  font-family: $heading-font;
  margin-right: 4px;
  margin-bottom: 4px;
  color: $black;
  background-color: $white;
  max-width: 100%;

  &:disabled {
    opacity: 0.5;
  }

  &.small:not(.select) {
    padding: 6px 8px;
  }

  &.inline {
    font-size: 14px;
    padding: 2px;
    border-radius: 3px;

    &:not(:active):not(:focus):not(:hover) {
      border: 1px solid transparent;
    }
  }
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
.select,
textarea {
  border: 1px solid $gray;
  border-radius: 3px;

  &:focus,
  &:hover {
    outline: none;
    border: 1px solid $red;
  }

  &.error {
    border-color: $red;
    background-color: rgba($red, 0.2);
    color: $red;
  }
}

input:not([type="submit"]):not([type="checkbox"]),
textarea {
  color: $black;
  text-shadow: 0 0 0 $black;

  &.error:not(:focus) {
    text-shadow: 0 0 0 $red;
  }

  &::input-placeholder {
    color: $gray;
    text-shadow: none;
    -webkit-text-fill-color: initial;
  }
}

input[type="submit"] {
  text-align: center;
  text-transform: uppercase;
  color: $off-white;
  padding: 17px 30px;
  text-transform: uppercase;
  background-color: $red;
  border-radius: 3px;
  appearance: none;
  font-size: 14px;

  &:hover,
  &:focus {
    background-color: $red;
    cursor: pointer;
    text-decoration: none;
    box-shadow: $shadow;
    // transform: translateY(-1px);
  }

  &:active {
    background-color: $dark-red;
    // transform: translateY(0px);
    box-shadow: none;
  }

  &.secondary,
  &.outline {
    background-color: $white;
    border: 1px solid $red;
    color: $red;

    &:active {
      background-color: $dark-red;
      color: $white;
    }
  }

  &.tertiary {
    background-color: $white;
    color: $red;

    &:hover {
      background-color: $red;
      color: $white;
    }

    &:active {
      background-color: $dark-red;
      color: $white;
    }
  }

  &.disabled,
  &:disabled {
    opacity: 0.5;
    border-color: $light-gray;

    &:hover {
      box-shadow: none;
      cursor: default;
    }
  }

  &.small {
    padding: 7px;
  }

  &.cancel {
    color: $gray;
    background-color: $white;
    border: none;

    &:hover {
      color: $red;
    }

    &:active {
      color: $dark-red;
    }
  }
}

.select {
  position: relative;
  padding: 0;
  overflow: hidden;
  background-color: $white;

  > select {
    appearance: none;
    display: block;
    background-color: transparent;
    border: 0;
    padding: 1em 2em 1em 1em;
    font-size: 1em;
    width: 100%;
    color: inherit;

    &::-ms-expand {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.secondary {
    border: none;
    background-color: transparent;

    &::after {
      color: $white;
    }

    > select {
      color: $white;
      border-bottom: 2px solid $white;
      border-radius: 0;
      padding: 0 30px 0 0;

      &:focus {
        outline: none;
      }

      option {
        color: $primary-black;
      }
    }
  }

  &.small > select {
    padding: 6px 28px 6px 6px;
    font-size: 13px;
  }
}

.select,
.caret {
  &::after {
    content: "";
    border: 2px solid $primary-black;
    border-top: none;
    border-left: none;
    width: 9px;
    height: 9px;
    transform: translateY(-2px) scaleY(0.9) rotate(45deg);
    display: block;
    position: absolute;
    right: 12px;
    opacity: 0.8;
    top: 50%;
    margin-top: -5px;
    pointer-events: none;
    transition: transform 0.1s ease-out;
  }

  &:hover::after,
  &:focus::after {
    border-color: $red;
  }
}

input[type="checkbox"],
input[type="radio"] {
  margin: 0 12px 0 0;
  display: inline-block;
  vertical-align: text-bottom;
  border: 1px solid $light-gray;
  background-color: $white;
  appearance: none;
  width: 20px;
  height: 20px;

  &:checked,
  &:indeterminate {
    border-color: $red;

    &::after {
      display: block;
      text-align: center;
      color: $red;
      overflow: hidden;
      position: relative;
    }
  }

  &:hover {
    border-color: $red;
    cursor: pointer;
  }
}

input[type="checkbox"] {
  border-radius: 1px;

  &:checked::after {
    content: "\e901";
    font-family: "icomoon";
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 19px;
    text-indent: -1px;
  }

  &:indeterminate::after {
    content: "\e91d";
    font-family: "icomoon";
    width: 20px;
    height: 20px;
    font-size: 18px;
    line-height: 19px;
    text-indent: -1px;
  }
}

input[type="radio"] {
  border-radius: 50%;
  position: relative;

  &:checked {
    &::after {
      content: "";
      background-color: $red;
      border-radius: 50%;
      position: absolute;
      left: 4px;
      top: 4px;
      bottom: 4px;
      right: 4px;
    }
  }
}

.label {
  font-size: 18px;
  font-weight: bold;
  display: block;
  margin: 10px 0 8px;
}

.checkboxes,
.radios,
.inputs {
  list-style: none;

  > label,
  li > label,
  li > span {
    display: block;
    padding: 14px 18px;

    &:hover {
      cursor: pointer;
      background-color: $translucent-red;
    }

    &.error {
      color: $red;
    }
  }

  &.inline {
    li {
      display: inline-block;
    }
  }
}

body p.form-error {
  color: $red;
  font-size: 13px;
  margin-top: -3px;
}

.label-input {
  > label:first-child,
  > span:first-child {
    font-size: 12px;
    display: block;
    margin: 2px 0;
  }

  > *:last-child {
    width: 100%;
  }
}

.icon-input {
  position: relative;

  > .icon {
    font-size: 80%;
    position: absolute;
    top: 50%;
    margin-top: -10px;

    &:first-child {
      left: 6px;
      width: 1em;
      text-align: center;
      line-height: 1.4em;
    }

    &:nth-child(2) {
      right: 6px;
    }
  }

  > input {
    width: 100%;

    &:first-child {
      padding-right: 26px;
    }

    &:nth-child(2) {
      padding-left: 26px;
    }
  }
}
